<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Demande complétée',
      headDescription:
        "Comparez des entreprises qualifiées sur Comparateurdepompesachaleur.be. Rapide, fiable et uniquement des devis de votre région. Économisez jusqu'à 40%.",
      path: '/quotes-requests/demande-completee',
    }
  },
}
</script>
